<template>
  <section id="body">
    <section id="Connect" v-bind:class="{ shake }">
      <form>
        <div v-bind:class="{ info, good, error, warning }">
          <p>{{ alert.message }}</p>
          <!--<p> v-show="input.ipaddress && input.id && input.key">{{ input.ipaddress}} / {{ login.id}} / {{input.key}}</p>-->
        </div>
        <v-card color="rgb(0,0,0,0.01)" flat outlined>
          <v-card-text>
            <c-text-field
              type="text"
              :disabled="connecting"
              name="Device IP"
              v-model="input.ipaddress"
              prepend-inner-icon="mdi-ip-network-outline"
              label="Address"
              hint="ie. 192.168.1.101"
              :readonly="readonlyIp"
            />
            <c-text-field
              type="text"
              v-if="input.port"
              :readonly="readonlyPort"
              :disabled="connecting"
              name="Port"
              v-model="input.port"
              prepend-inner-icon="mdi-ip-network-outline"
              label="Port"
              hint="ie. 80"
            />
            <c-text-field
              v-if="!input.jwt"
              :disabled="connecting"
              type="text"
              name="Api ID"
              prepend-inner-icon="mdi-account-outline"
              v-model="input.id"
              label="ID"
            />
            <c-text-field
              v-if="!input.jwt"
              name="Api Key"
              v-model="input.key"
              label="Key"
              prepend-inner-icon="mdi-lock-outline"
              :disabled="connecting"
              :append-icon="passwordVisible ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="() => (passwordVisible = !passwordVisible)"
              :type="passwordVisible ? 'text' : 'password'"
            />
            <c-text-field
              v-if="input.jwt"
              :readonly="readonlyJwt"
              name="Api Key"
              v-model="input.jwt"
              label="Token"
              type="text"
              prepend-inner-icon="mdi-account-key-outline"
              :disabled="connecting"
            />
          </v-card-text>
          <v-card-actions>
            <v-btn
              class="mx-3"
              color="success"
              type="button"
              width="70%"
              v-on:click="connect()"
              :loading="connecting"
              :disabled="connecting"
              >Connect</v-btn
            >
            <v-spacer />
            <v-btn
              class="connections mx-3"
              color="white"
              icon
              @click.stop="showPrevConnections = true"
              :disabled="connecting"
            >
              <v-icon>mdi-history</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </form>
    </section>
    <v-dialog v-model="showPrevConnections" persistent max-width="290">
      <v-card>
        <v-list rounded three-line>
          <v-subheader>PREVIOUS CONNECTIONS</v-subheader>
          <v-list-item-group>
            <v-list-item
              v-for="(connection, key) in previousConnections.slice().reverse()"
              :key="key"
              @click="
                connectTo(connection.ipaddress, connection.id, connection.key);
                showPrevConnections = false;
              "
            >
              <v-list-item-avatar>
                <v-img
                  v-if="connection.model == 'cr5400i'"
                  :src="require('../../public/img/readers/cr5400i-60x60.png')"
                ></v-img>
                <v-img
                  v-if="connection.model == 'at10ki'"
                  :src="require('../../public/img/readers/at10ki-60x60.png')"
                ></v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title
                  v-html="connection.serial"
                ></v-list-item-title>

                <v-list-item-subtitle
                  ><b>IP Address:</b>
                  {{ connection.ipaddress }}</v-list-item-subtitle
                >
                <v-list-item-subtitle
                  ><b>ID:</b> {{ connection.id }} <b>Key:</b>
                  {{ "********" }}</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="primary" @click="showPrevConnections = false">
              Close
            </v-btn>
          </v-card-actions>
        </v-list>
      </v-card>
    </v-dialog>

    <!--<v-dialog v-model="showIDVSettings" persistent max-width="500">
      <v-card>
        <v-list rounded three-line>
          <v-subheader
            >Welcome to the IDV Demo! Lets get set up before proceeding.
          </v-subheader>
          <v-form ref="idvform" v-model="idvSettingsValid" lazy-validation>
            <v-text-field
              v-model="idvSettings.apiKey"
              label="API Key"
              :rules="[v => !!v || 'API Key is required']"
              required
            ></v-text-field>

            <v-text-field
              v-model="idvSettings.idvServer"
              label="IDV Server"
              :rules="[v => !!v || 'IDV Server is required']"
              required
            ></v-text-field>
            <v-text-field
              v-model="idvSettings.verificationUri"
              label="Verification Uri"
              :rules="[v => !!v || 'Verification Uri is required']"
              required
            ></v-text-field>
            <v-text-field
              v-model="idvSettings.faceMatchUri"
              label="Face Match Uri"
              :rules="[v => !!v || 'Face Match Uri is required']"
              required
            ></v-text-field>
            <v-text-field
              v-model="idvSettings.appClientId"
              label="App Client ID"
              :rules="[v => !!v || 'App Client ID is required']"
              required
            ></v-text-field>
            <v-text-field
              v-model="idvSettings.appClientSecret"
              label="App Client Secret"
              :rules="[v => !!v || 'App Client Secret is required']"
              required
            ></v-text-field>
            <v-text-field
              v-model="idvSettings.authenticationServer"
              label="Authentication Server"
              :rules="[v => !!v || 'Authentication Server is required']"
              required
            ></v-text-field>
            <v-text-field
              v-model="idvSettings.authenticationUri"
              label="Authentication Uri"
              :rules="[v => !!v || 'Authentication Uri is required']"
              required
            ></v-text-field>
            <v-text-field
              v-model="idvSettings.primaryChannel"
              label="Primary Channel"
              :rules="[v => !!v || 'Primary Channel is required']"
              required
            ></v-text-field>
            <v-text-field
              v-model="idvSettings.secondaryChannel"
              label="Secondary Channel"
              :rules="[v => !!v || 'Secondary Channel is required']"
              required
            ></v-text-field>

            <v-checkbox
              v-model="idvSettingsCheckbox"
              label="Save these settings?"
            ></v-checkbox>
          </v-form>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="primary" @click="saveIDVSettings"  :disabled="!idvSettingsValid">
              Continue
            </v-btn>
          </v-card-actions>
        </v-list>
      </v-card>
    </v-dialog>-->
  </section>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "Connect",
  data() {
    return {
      input: {
        ipaddress: "",
        id: "",
        key: "",
        jwt: null,
        port: null
      },
      alert: {
        message: "Connect to a Device"
      },
      readonlyIp: false,
      readonlyPort: false,
      readonlyJwt: false,
      tab: null,
      passwordVisible: false,
      title: "iSeries Reader",
      info: true,
      good: false,
      error: false,
      warning: false,
      shake: false,
      connecting: false,
      previousConnections: [],
      newConnection: {
        serial: "",
        ipaddress: "",
        id: "",
        key: "",
        jwt: null,
        port: null
      },
      showPrevConnections: false
    };
  },
  computed: {
    webApi: function() {
      return window.webApiModule;
    }
  },
  methods: {
    ...mapActions("webapi", ["setConnection"]),
    showModal() {
      console.log(this.previousConnections);
    },
    connectTo(ip, id, key) {
      this.input.ipaddress = ip;
      this.input.id = id;
      this.input.key = key;
      this.isAPreviousConnection = true;
      this.connect();
    },
    timeoutPromise(ms = 15000) {
      return new Promise((_, reject) => {
        setTimeout(reject.bind(this, "Connection Timed Out"), ms);
      });
    },
    startSession(webApi) {
      // vue looses this inside the promise, so save the only object we need
      return Promise.race([
        new Promise(resolve => {
          webApi.once("SESSION_IN_USE", () => {
            resolve(false);
            return true;
          });
          webApi.once("SESSION_OPENED", () => {
            resolve(true);
            return true;
          });
          webApi.start_session();
        }),
        this.timeoutPromise()
      ]);
    },
    openConnection(webApi, { jwt, ipaddress, id, key, port }) {
      // vue looses this inside the promise, so save the only object we need
      return Promise.race([
        new Promise((resolve, reject) => {
          webApi.once("CONNECTED", () => {
            resolve();
            return true;
          });
          webApi.once("AUTHENTICATED", () => {
            resolve();
            return true;
          });
          webApi.once("UNPROVISIONED_DEVICE", () => {
            reject("The Device is Unprovisioned");
            return true;
          });
          webApi.once("NOT_AUTHORIZED", () => {
            reject("Incorrect ID or Key!");
            return true;
          });
          webApi.once("INVALID_KEY", () => {
            reject("Incorrect ID or Key!");
            return true;
          });
          webApi.once("INTERNAL_ERROR", () => {
            reject("Reader Internal Error OccuredD");
            return true;
          });
          if (jwt) {
            webApi.open_jwt_connection(ipaddress, jwt, port);
          } else {
            webApi.open_connection(ipaddress, id, key, undefined, port);
          }
        }),
        this.timeoutPromise()
      ]);
    },
    getDeviceInfo(webApi) {
      return Promise.race([
        new Promise(resolve => {
          webApi.once("DEVICE_INFO", data => {
            resolve(data);
            return true;
          });
          webApi.get_device_info();
        }),
        this.timeoutPromise()
      ]);
    },
    async doConnection() {
      try {
        await this.openConnection(this.webApi, this.input);

        this.alert.message = "Connected!";
        this.good = false;
        this.setConnection(this.webApi);
        this.$emit("authenticated", true);

        if (await this.startSession(this.webApi)) {
          this.info = false;
          this.good = true;
          this.alert.message = "Session started!";
          this.$emit("authenticated", true);

          const data = await this.getDeviceInfo(this.webApi);

          this.$emit("update-device-serial", data.serial);
          this.$loading.hide();
          console.log(data);

          this.webApi.on("CLOSED", () => {
            console.log("Connection Closed");
          });

          if (!this.isAPreviousConnection) {
            this.addConnection(data.serial, data.model);
          }
          //this.$router.push({name:"dashboard",params: {deviceInfo: data,webApiSession: this.webApi }});

          return data;
        } else {
          this.info = false;
          this.error = true;
          this.$loading.hide();
          this.shake = true;
          this.alert.message = "Session is in Use";
          this.webApi.close_connection();
        }
      } catch (timeoutErr) {
        this.$loading.hide();
        this.warning = true;
        this.shake = true;
        console.error(timeoutErr);
        this.alert.message = timeoutErr; //"Connection Timed Out";
      }
    },
    async connect() {
      this.warning = false;
      this.error = false;
      this.info = true;
      //console.log("before connecting.. " + this.shake);
      this.shake = false;
      //console.log("connecting.. " + this.shake);
      this.connecting = true;
      if (
        this.input.ipaddress != "" &&
        (this.input.jwt || (this.input.id != "" && this.input.key != ""))
      ) {
        this.alert.message = "Connecting...";
        this.$loading.show({ delay: 0, background: "rgba(0,0,0, 0.5)" }); //delay 0ms, default is 300ms

        let data = null;
        if ((data = await this.doConnection())) {
          this.$router.push({
            name: "capture",
            params: { deviceInfo: data, webApiSession: this.webApi }
          });
        }
      } else {
        if (this.input.ipaddress == "") {
          this.shake = true;
          this.warning = true;
          this.alert.message = "Please enter a IP Address";
        } else if (this.input.id == "") {
          this.shake = true;
          this.warning = true;
          this.alert.message = "Please enter an Admin ID";
        } else if (this.input.key == "") {
          this.shake = true;
          this.warning = true;
          this.alert.message = "Please enter an Admin Key";
        }
      }

      this.connecting = false;
    },
    addConnection(serial, model) {
      if (!this.input.ipaddress || !this.input.id || !this.input.key) {
        return;
      }

      this.newConnection.serial = serial;
      this.newConnection.ipaddress = this.input.ipaddress;
      this.newConnection.id = this.input.id;
      this.newConnection.key = this.input.key;
      this.newConnection.model = model;

      console.log(this.newConnection);

      this.previousConnections.push(this.newConnection);
      this.saveConnections();
    },
    saveConnections() {
      if (this.previousConnections.length > 5) {
        this.previousConnections.splice(0, 1);
      }
      const parsed = JSON.stringify(this.previousConnections);
      localStorage.setItem("previousConnections", parsed);
    },
    saveIDVSettings() {
      this.validateIDVForm();

      setTimeout(() => {
        if (this.idvSettingsValid) {
          if (this.idvSettingsCheckbox) {
            localStorage.setItem(
              "idvSettings",
              JSON.stringify(this.idvSettings)
            );
          }
          this.showIDVSettings = false;
        } else {
          console.log("not valid");
        }
      }, 1000);
    },
    validateIDVForm() {
      this.$refs.idvform.validate();
    }
  },
  async mounted() {
    if (this.$route.query.ip) {
      this.input.ipaddress = this.$route.query.ip;
      this.readonlyIp = !this.$route.query.edit;
    }
    if (this.$route.query.jwt) {
      this.input.jwt = this.$route.query.jwt;
      this.readonlyjwt = !this.$route.query.edit;
    }
    if (this.$route.query.port) {
      this.input.port = this.$route.query.port;
      this.readonlyPort = !this.$route.query.edit;
    }
    if (this.$route.query.id) {
      this.input.id = this.$route.query.id;
    }
    if (this.$route.query.key) {
      this.input.key = this.$route.query.key;
    }

    if (localStorage.getItem("darkModeSwitch")) {
      this.$vuetify.theme.dark = JSON.parse(localStorage.darkModeSwitch);
    }

    if (localStorage.getItem("previousConnections")) {
      try {
        this.previousConnections = JSON.parse(
          localStorage.getItem("previousConnections")
        );
      } catch (e) {
        localStorage.removeItem("previousConnections");
      }
    }

    if (this.$route.query.login) {
      await this.connect();
    }

    var isIE = /*@cc_on!@*/ false || !!document.documentMode;

    if (isIE) {
      this.$router.push({
        name: "unsupported"
      });
    }
  }
};
</script>

<style scoped>
section#body {
  background-image: url("../../public/img/background_3.png");
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #ffffff;
}

form {
  display: flex;
  flex-direction: column;
  padding: 15px;
}

section#Connect {
  background-color: rgba(0, 0, 0, 0.72);
  min-width: 25%;
  min-height: 25%;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  margin-top: 100px;
  margin-bottom: 100px;
}

/* button {
  width: 60%;
  margin: 10px auto;
} */

.connections {
  background-color: rgba(0, 0, 0, 0.72);
  background-color: #444444;
  border-radius: 4px;
  height: 36px;
  width: 36px;
}

.connections:hover {
  background-color: #555555;
}

/* .buttonbar {
  padding: 0px 5px;
  margin: 0px 0;
} */

.info {
  margin: 10px 0;
  width: 100%;
  text-align: center;
  min-height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
}

.error {
  margin: 10px 0;
  width: 100%;
  text-align: center;
  min-height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #a90e00;
  background-color: #ff3c41;
}

.warning {
  margin: 10px 0;
  width: 100%;
  text-align: center;
  min-height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #a90e00;
}

.good {
  margin: 10px 0;
  width: 100%;
  text-align: center;
  min-height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #416d50;
  background-color: #47cf73;
  color: #416d50;
}

.info p {
  color: #e0dada;
  margin: auto;
  padding: 5px;
}
.error p {
  color: #e0dada;
  margin: auto;
  padding: 5px;
}
.warning p {
  color: #e0dada;
  margin: auto;
  padding: 5px;
}
.good p {
  color: #e0dada;
  margin: auto;
  padding: 5px;
}

@-webkit-keyframes shake {
  from,
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
    transform: translate3d(-5px, 0, 0);
  }

  20%,
  40%,
  60%,
  80% {
    -webkit-transform: translate3d(5px, 0, 0);
    transform: translate(5px, 0, 0);
  }
}

.shake {
  animation-name: shake;
  animation-duration: 0.5s;

  /* animation-fill-mode: both; */
}
</style>
